import { useDispatch, useSelector } from 'react-redux'
import { Print, PrintDisabled } from '@mui/icons-material'

import { Dispatch, RootState } from '@/utilities/store'
import { IconButton } from '@/components'

const Printer = () => {
  const printerIpAddress = useSelector((state: RootState) => state.app.printerIpAddress)

  const dispatch = useDispatch<Dispatch>()

  const setPrinterIpAddress = async () => {
    const ipAddress = prompt('Enter printer ip address:', printerIpAddress)
    if (ipAddress === null) {
      return
    }

    dispatch.app.setPrinterIpAddress(ipAddress)
  }

  return (
    <IconButton
      EnabledIcon={Print}
      DisabledIcon={PrintDisabled}
      enabled={!!printerIpAddress}
      onClick={setPrinterIpAddress}
    />
  )
}

export default Printer
