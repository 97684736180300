import { useEffect, useState } from 'react'
import styled from 'styled-components'

import BrandLogoIcon from '@/assets/icons/brand-logo.svg'
import { ReactNativeWebViewMessages } from '@/utilities/constants'

const BrandLogoStyled = styled.img`
  height: 2rem;
`

const BrandLogo = () => {
  const [count, setCount] = useState<number>(0)

  const handleClick = () => {
    if (!window.ReactNativeWebView) {
      return
    }

    if (count === 9) {
      window.ReactNativeWebView.postMessage(ReactNativeWebViewMessages.CHANGE_ENV)

      setCount(0)

      return
    }

    setCount((prevCount) => prevCount + 1)
  }

  useEffect(() => {
    if (!count) {
      return
    }

    const timeout = setTimeout(() => {
      setCount(0)
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [count])

  return <BrandLogoStyled src={BrandLogoIcon} alt="brand-logo" onClick={handleClick} />
}

export default BrandLogo
