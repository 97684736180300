import { Box, IconButton, IconButtonProps } from '@mui/material'
import { AlternateEmail } from '@mui/icons-material'

interface ReceiptButtonProps extends IconButtonProps {}

const ReceiptButton = (props: ReceiptButtonProps) => {
  return (
    <Box color="#ffffff">
      <IconButton type="button" size="medium" color="inherit" {...props}>
        <AlternateEmail fontSize="small" />
      </IconButton>
    </Box>
  )
}

export default ReceiptButton
