import { FormEventHandler } from 'react'
import { Dialog as MDialog, Box, Button, DialogProps as MDialogProps } from '@mui/material'
import styled, { AnyStyledComponent } from 'styled-components'

import CloseButton from './components/CloseButton'

export interface DialogProps extends MDialogProps {
  confirmButtonText?: string
  disabled?: boolean
  onConfirm?: () => void
  onClose?: () => void
}

const ConfirmButton = styled(Button as AnyStyledComponent).attrs({
  variant: 'contained',
  size: 'large',
  sx: {
    backgroundColor: '#115511',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#115511',
    },
    '&:active': {
      backgroundColor: '#115511',
    },
    '&:focus': {
      backgroundColor: '#115511',
    },
  },
})``

const Dialog = ({ confirmButtonText, disabled, children, onConfirm, onClose, ...props }: DialogProps) => {
  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()

    onConfirm?.()
  }

  return (
    <MDialog fullWidth onClose={onClose} {...props}>
      <Box px={10} pt={10} pb={6} component="form" onSubmit={handleSubmit}>
        <Box position="absolute" top={20} right={20}>
          <CloseButton onClick={onClose} />
        </Box>

        {children}

        {!!confirmButtonText && (
          <Box pt={6} display="flex" justifyContent="center">
            <ConfirmButton type="submit" disabled={disabled}>
              {confirmButtonText}
            </ConfirmButton>
          </Box>
        )}
      </Box>
    </MDialog>
  )
}

export default Dialog
