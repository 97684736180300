import { Button } from '@mui/material'

interface AppVersionButtonProps {
  isNewAppVersion?: boolean
  onClick?: () => void
}

const AppVersionButton = ({ isNewAppVersion, onClick }: AppVersionButtonProps) => {
  return (
    <Button
      variant="outlined"
      fullWidth
      color={isNewAppVersion ? 'error' : 'success'}
      sx={{ textTransform: 'none' }}
      onClick={onClick}
    >
      Use {isNewAppVersion ? 'old' : 'new'} app version
    </Button>
  )
}

export default AppVersionButton
