import { createModel } from '@rematch/core'
import { AxiosResponse } from 'axios'

import { RootModel } from '@/models'
import { CredentialsContract, KdsUserAuthenticationContract, KdsUserProfileContract } from '@/types/api'
import { api } from '@/utilities/api'

interface AuthenticationState {
  user: KdsUserProfileContract | null
  token: string
}

const initialState: AuthenticationState = {
  user: null,
  token: '',
}

export const authentication = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setUser(state, user: KdsUserProfileContract) {
      return { ...state, user }
    },
    setToken(state, token: string) {
      return { ...state, token }
    },
    resetState() {
      return initialState
    },
  },
  effects: (dispatch) => ({
    async login(payload: CredentialsContract) {
      const res: AxiosResponse<KdsUserAuthenticationContract> = await api.post('/users/authenticate', payload)

      dispatch.authentication.setUser(res.data.user!)

      dispatch.authentication.setToken(res.data.token!)

      return res.data
    },
    async getUser() {
      const res: AxiosResponse<KdsUserProfileContract> = await api.get('/users/profile')

      dispatch.authentication.setUser(res.data)

      return res.data
    },
  }),
})
