import { createModel } from '@rematch/core'
import { AxiosResponse } from 'axios'
import { createSelector } from '@rematch/select'

import { RootModel } from '@/models'
import { KdsVenueContract } from '@/types/api'
import { api } from '@/utilities/api'
import { RootState } from '@/utilities/store'

interface LocationsState {
  locations: KdsVenueContract[]
}

const initialState: LocationsState = {
  locations: [],
}

export const locations = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setLocations(state, locations: KdsVenueContract[]) {
      return { ...state, locations }
    },
    resetState() {
      return initialState
    },
  },
  effects: (dispatch) => ({
    async getLocations() {
      const { data }: AxiosResponse<KdsVenueContract[]> = await api.get('/locations')

      dispatch.locations.setLocations(data)

      return data
    },
  }),
})

export const selectLocationsObj = createSelector(
  ({ locations }: RootState) => locations,
  ({ locations }) => {
    return locations.reduce<{ [locationId: string]: KdsVenueContract }>((obj, location) => {
      const locationId = location.id
      if (locationId) {
        obj[locationId] = location
      }

      return obj
    }, {})
  },
)

export const selectLocation = createSelector(
  ({ locations }: RootState) => locations,
  selectLocationsObj,
  (_: RootState, locationId?: string) => locationId,
  (_, locationsObj, locationId) => {
    if (!locationId) {
      return
    }

    return locationsObj[locationId]
  },
)
