import '@/utilities/sentry'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { getPersistor } from '@rematch/persist'
import { PersistGate } from 'redux-persist/lib/integration/react'

import '@/index.css'
import '@/utilities/epson'
import { store } from '@/utilities/store'
import Router from '@/pages'

const persistor = getPersistor()

ReactDOM.createRoot(document.getElementById('root')!).render(
  // @ts-expect-error
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <Router />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
)
