import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  captureSentryException,
  getFilteredOrders,
  getPrinterImgCtx,
  getWindowSizes,
  playNewOrderSound,
  waitForMS,
} from '@/utilities/functions'
import { selectFilteredKitchenOrders, selectFilteredOrders, selectOrdersToPrint } from '@/models/orders'
import { PrintData, print } from '@/utilities/print'
import { RootState, Dispatch } from '@/utilities/store'
import { KdsDisplayOrderContract } from '@/types/api'
import { selectLocation } from '@/models/locations'

export interface WindowSizes {
  width: number
  height: number
}

export const useWindowSizes = () => {
  const [sizes, setSizes] = useState<WindowSizes>(getWindowSizes())

  const handleResize = () => {
    setSizes(getWindowSizes())
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return sizes
}

export const usePrintOrders = () => {
  const { printerIpAddress, printing, printerHasError } = useSelector((state: RootState) => state.app)

  const ordersToPrint = useSelector(selectOrdersToPrint)

  const dispatch = useDispatch<Dispatch>()

  const markKitchenOrderPrinted = useCallback(
    async (orderId: string) => {
      try {
        await dispatch.orders.markKitchenOrderPrinted(orderId)
      } catch (error) {
        captureSentryException(error)

        console.error(error)
      }
    },
    [dispatch.orders],
  )

  const printOrder = useCallback(
    async (
      printerIpAddress: string,
      order: KdsDisplayOrderContract,
      printOrderType: boolean,
      receiptLogoImageName: string | null,
    ) => {
      const data: PrintData[] = []

      if (receiptLogoImageName) {
        const imgCtx = await getPrinterImgCtx(`${process.env.VITE_APP_IMGIX_URL}/${receiptLogoImageName}`)
        if (imgCtx) {
          data.push({
            type: 'image',
            ctx: imgCtx,
            newLines: 3,
          })
        }
      }

      let header = ''
      if (printOrderType && order.type) {
        header += order.type
      }
      if (order.table) {
        header += header ? `\t|\t${order.table}` : order.table
      }
      if (header) {
        data.push({
          type: 'text',
          textSize: 2,
          newLines: 3,
          text: header,
        })
      }

      if (order.number) {
        data.push({
          type: 'text',
          textSize: 3,
          newLines: order.commentToKitchen ? 1 : 3,
          text: `#${order.number}`,
        })
      }

      if (order.commentToKitchen) {
        data.push({
          type: 'text',
          textSize: 2,
          newLines: 3,
          text: order.commentToKitchen,
        })
      }

      let products = ''
      order.orderProducts?.forEach((orderProduct) => {
        products += `${orderProduct.count}x ${orderProduct.title}\n`

        orderProduct.options?.forEach((option) => {
          products += `\t${option.count}x ${option.title}\n`
        })
      })
      if (products) {
        data.push({
          type: 'text',
          textSize: 1,
          newLines: 2,
          text: products,
        })
      }

      if (order.guestIdentification) {
        data.push({
          type: 'text',
          textSize: 2,
          newLines: 3,
          text: `Name: ${order.guestIdentification}`,
        })
      }

      if (order.name) {
        data.push({
          type: 'text',
          textSize: 1,
          newLines: 3,
          text: order.name,
          center: true,
        })
      }

      await print(printerIpAddress, data)
    },
    [],
  )

  const printOrders = useCallback(
    async (
      orders: KdsDisplayOrderContract[],
      printOrderType: boolean,
      receiptLogoImageName: string | null,
      printerIpAddress: string,
    ) => {
      dispatch.app.setPrinting(true)

      try {
        for (const order of orders) {
          const orderId = order.id
          if (!orderId) {
            continue
          }

          await printOrder(printerIpAddress, order, printOrderType, receiptLogoImageName)

          await markKitchenOrderPrinted(orderId)

          await waitForMS(1000)
        }
      } catch (error) {
        console.error(error)

        dispatch.app.setPrinterHasError(true)
      } finally {
        dispatch.app.setPrinting(false)
      }
    },
    [dispatch.app, markKitchenOrderPrinted, printOrder],
  )

  useEffect(() => {
    if (printing || !ordersToPrint?.orders?.length || printerHasError || !printerIpAddress) {
      return
    }

    printOrders(
      ordersToPrint.orders,
      ordersToPrint.printOrderType,
      ordersToPrint.receiptLogoImageName,
      printerIpAddress,
    )
  }, [
    ordersToPrint?.orders,
    ordersToPrint?.printOrderType,
    ordersToPrint?.receiptLogoImageName,
    printOrders,
    printerHasError,
    printerIpAddress,
    printing,
  ])

  useEffect(() => {
    if (!printerHasError) {
      return
    }

    const timeout = setTimeout(() => {
      dispatch.app.setPrinterHasError(false)
    }, 5000)

    return () => {
      clearTimeout(timeout)
    }
  }, [dispatch.app, printerHasError])
}

export const useOrdersFetch = ({
  locationId,
  playOrdersSound,
  playKitchenOrdersSound,
}: {
  locationId?: string
  playOrdersSound?: boolean
  playKitchenOrdersSound?: boolean
}) => {
  const [initLoading, setInitLoading] = useState<boolean>(true)

  const { soundEnabled, printing } = useSelector((state: RootState) => state.app)

  const orders = useSelector((state: RootState) => selectFilteredOrders(state, locationId))
  const kitchenOrders = useSelector((state: RootState) => selectFilteredKitchenOrders(state, locationId))
  const location = useSelector((state: RootState) => selectLocation(state, locationId))

  const dispatch = useDispatch<Dispatch>()

  const previousOrdersLength = useRef<number>(0)
  const previousKitchenOrdersLength = useRef<number>(0)

  const getOrders = useCallback(async () => {
    if (!locationId) {
      return
    }

    try {
      return await Promise.all([dispatch.orders.getOrders(locationId), dispatch.orders.getKitchenOrders(locationId)])
    } catch (error) {
      console.error(error)
    }
  }, [dispatch.orders, locationId])

  const init = useCallback(async () => {
    setInitLoading(true)

    const res = await getOrders()
    if (!res) {
      setInitLoading(false)
      return
    }

    const [orders, kitchenOrders] = res

    previousOrdersLength.current = getFilteredOrders(location, orders).length
    previousKitchenOrdersLength.current = getFilteredOrders(location, kitchenOrders).length

    setInitLoading(false)
  }, [getOrders, location])

  useEffect(() => {
    init()
  }, [init])

  useEffect(() => {
    if (initLoading || printing) {
      return
    }

    const interval = setInterval(() => {
      getOrders()
    }, 10000)

    return () => {
      clearInterval(interval)
    }
  }, [getOrders, initLoading, printing])

  useEffect(() => {
    if (initLoading) {
      return
    }

    if (playOrdersSound && soundEnabled && orders.created.length > previousOrdersLength.current) {
      playNewOrderSound()
    }

    previousOrdersLength.current = orders.created.length
  }, [initLoading, orders.created.length, playOrdersSound, soundEnabled])

  useEffect(() => {
    if (initLoading) {
      return
    }

    if (playKitchenOrdersSound && soundEnabled && kitchenOrders.created.length > previousKitchenOrdersLength.current) {
      playNewOrderSound()
    }

    previousKitchenOrdersLength.current = kitchenOrders.created.length
  }, [initLoading, kitchenOrders.created.length, playKitchenOrdersSound, soundEnabled])

  return { initLoading }
}
