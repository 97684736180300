import { init, RematchDispatch, RematchRootState } from '@rematch/core'
import persistPlugin from '@rematch/persist'
import storage from 'redux-persist/lib/storage'
import loadingPlugin, { ExtraModelsFromLoading } from '@rematch/loading'
import selectPlugin from '@rematch/select'
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'

import { models, RootModel } from '@/models'

type FullModel = ExtraModelsFromLoading<RootModel>

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['authentication'],
}

const appPersistConfig = {
  key: 'app',
  storage,
  whitelist: ['printerIpAddress', 'soundEnabled', 'isNewAppVersion'],
}

export const store = init<RootModel, FullModel>({
  models,
  plugins: [persistPlugin(rootPersistConfig), loadingPlugin(), selectPlugin()],
  redux: {
    combineReducers: (reducers) =>
      combineReducers({
        ...reducers,
        app: persistReducer(appPersistConfig, reducers.app),
      }),
  },
})

export type Store = typeof store

export type Dispatch = RematchDispatch<RootModel>

export type RootState = RematchRootState<RootModel, FullModel>
