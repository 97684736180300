import styled, { AnyStyledComponent } from 'styled-components'
import { Box, Typography } from '@mui/material'

import Dialog, { DialogProps } from '@/components/Dialog'
import StopIcon from '@/assets/icons/stop.svg'

const StopImg = styled.img`
  width: 7.813rem;
  height: auto;
`

const Title = styled(Typography as AnyStyledComponent).attrs({
  fontWeight: 500,
  align: 'center',
})`
  color: #000000;
`

interface MarkPaidDialogProps extends DialogProps {
  onDone?: (email: string) => void
}

const MarkPaidDialog = (props: MarkPaidDialogProps) => {
  return (
    <Dialog confirmButtonText="Confirm" {...props}>
      <Box display="flex" flexDirection="column" alignItems="center" rowGap={3}>
        <StopImg src={StopIcon} alt="stop" />

        <Title>Mark as paid?</Title>
      </Box>
    </Dialog>
  )
}

export default MarkPaidDialog
