import { Add } from '@mui/icons-material'
import { Fab } from '@mui/material'
import styled, { AnyStyledComponent } from 'styled-components'
import { isAxiosError } from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'

import BaseOrderDialog from './components/BaseOrderDialog'
import { ErrorContract } from '@/types/api'
import { Dispatch, RootState } from '@/utilities/store'

export const ADD_BASE_ORDER_BUTTON_SIZE: number = 72 as const

interface AddBaseOrderButtonProps {
  locationId?: string
}

const FabStyled = styled(Fab as AnyStyledComponent).attrs<AddBaseOrderButtonProps>({
  sx: {
    backgroundColor: '#bfbfbf',
    width: ADD_BASE_ORDER_BUTTON_SIZE,
    height: ADD_BASE_ORDER_BUTTON_SIZE,
    fontSize: '3rem',
    color: 'success.main',
    ':hover': {
      backgroundColor: '#bfbfbf',
    },
  },
})<AddBaseOrderButtonProps>``

const AddBaseOrderButton = ({ locationId }: AddBaseOrderButtonProps) => {
  const [baseOrderDialogOpen, setBaseOrderDialogOpen] = useState<boolean>(false)

  const createBaseOrderLoading = useSelector((state: RootState) => state.loading.effects.orders.createBaseOrder)

  const dispatch = useDispatch<Dispatch>()

  const toggleBaseOrderDialogOpen = () => {
    setBaseOrderDialogOpen((prevBaseOrderDialogOpen) => !prevBaseOrderDialogOpen)
  }

  const handleCreateBaseOrder = async (orderNumber: number) => {
    if (!locationId) {
      return
    }

    try {
      await dispatch.orders.createBaseOrder({ number: orderNumber, locationId })

      toggleBaseOrderDialogOpen()
    } catch (error) {
      console.error(error)

      if (!isAxiosError<ErrorContract>(error)) {
        return
      }

      alert(error.response?.data.message)
    }
  }

  return (
    <>
      <FabStyled disableRipple disabled={!locationId || createBaseOrderLoading} onClick={toggleBaseOrderDialogOpen}>
        <Add color="inherit" fontSize="inherit" />
      </FabStyled>

      <BaseOrderDialog
        open={baseOrderDialogOpen}
        loading={createBaseOrderLoading}
        onCreate={handleCreateBaseOrder}
        onClose={toggleBaseOrderDialogOpen}
      />
    </>
  )
}

export default AddBaseOrderButton
