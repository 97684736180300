import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

import { RootState } from '@/utilities/store'

const ProtectedRoutes = () => {
  const token = useSelector((state: RootState) => state.authentication.token)

  if (!token) {
    return <Navigate to="/" replace />
  }

  return <Outlet />
}

export default ProtectedRoutes
