import { ButtonHTMLAttributes } from 'react'
import styled from 'styled-components'

import { Icon } from '@/types/mui'

interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  EnabledIcon: Icon
  DisabledIcon: Icon
  enabled?: boolean
}

const ButtonContainer = styled.button`
  width: 3rem;
  height: 3rem;
  background-color: #bfbfbf;
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
  display: grid;
  place-items: center;

  :enabled {
    cursor: pointer;
  }
`

const IconButton = ({ EnabledIcon, DisabledIcon, enabled, ...props }: IconButtonProps) => {
  return (
    <ButtonContainer {...props}>
      {enabled ? <EnabledIcon fontSize="large" color="success" /> : <DisabledIcon fontSize="large" color="error" />}
    </ButtonContainer>
  )
}

export default IconButton
