import { ChangeEvent, FormEvent, HTMLInputTypeAttribute, ReactNode, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, CardContent, Grid, TextField, Typography } from '@mui/material'
import { isAxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'

import { CredentialsContract, ErrorContract } from '@/types/api'
import { Dispatch, RootState } from '@/utilities/store'
import { logout, setSentryUser } from '@/utilities/functions'

const FORM_DATA: FormData[] = [
  {
    name: 'email',
    type: 'email',
    label: 'Email',
  },
  {
    name: 'password',
    type: 'password',
    label: 'Password',
  },
]

interface FormData {
  name: keyof CredentialsContract
  type: HTMLInputTypeAttribute
  label: ReactNode
}

const Login = () => {
  const [user, setUser] = useState<CredentialsContract>({ email: '', password: '' })
  const [loginFailedCount, setLoginFailedCount] = useState<number>(0)

  const isNewAppVersion = useSelector((state: RootState) => state.app.isNewAppVersion)

  const loginLoading = useSelector((state: RootState) => state.loading.effects.authentication.login)
  const getLocationsLoading = useSelector((state: RootState) => state.loading.effects.locations.getLocations)

  const loading = useMemo(() => {
    return loginLoading || getLocationsLoading
  }, [getLocationsLoading, loginLoading])

  const dispatch = useDispatch<Dispatch>()

  const navigate = useNavigate()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUser((prevUser) => ({ ...prevUser, [e.target.name]: e.target.value }))
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    try {
      const authUser = await dispatch.authentication.login(user)

      setSentryUser({ id: authUser.user?.id })

      const locations = await dispatch.locations.getLocations()
      if (!locations.length) {
        logout()

        alert("User don't have locations.")

        return
      }

      const locationId = locations[0].id!

      navigate(`/${locationId}/orders`, { replace: true })
    } catch (error) {
      console.error(error)

      if (isNewAppVersion) {
        setLoginFailedCount((prevLoginFailedCount) => (prevLoginFailedCount += 1))
      }

      if (!isAxiosError<ErrorContract>(error)) {
        return
      }

      alert(error.response?.data.message)
    }
  }

  const handleUseOldAppVersionClick = () => {
    setLoginFailedCount(0)

    dispatch.app.setIsNewAppVersion(false)
  }

  return (
    <Grid container justifyContent="center" p={2}>
      <Grid item md={4} xs={12}>
        <Card raised>
          <CardContent component="form" onSubmit={handleSubmit}>
            <Typography variant="h5">Login</Typography>

            <Grid container direction="column" rowSpacing={2} py={4}>
              {FORM_DATA.map((data, index) => (
                <Grid key={index} item xs>
                  <TextField
                    name={data.name}
                    type={data.type}
                    label={data.label}
                    fullWidth
                    required
                    disabled={loading}
                    value={user[data.name]}
                    onChange={handleChange}
                  />
                </Grid>
              ))}

              {loginFailedCount >= 3 && (
                <Grid item xs>
                  <Button sx={{ textTransform: 'none' }} onClick={handleUseOldAppVersionClick}>
                    Use old app version?
                  </Button>
                </Grid>
              )}
            </Grid>

            <Button type="submit" variant="contained" fullWidth disabled={loading}>
              Login ({isNewAppVersion ? 'new' : 'old'})
            </Button>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default Login
