import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { isAxiosError } from 'axios'
import { useParams } from 'react-router-dom'

import { Spinner, ReceiptDialog, MarkPaidDialog } from '@/components'
import { Dispatch, RootState } from '@/utilities/store'
import { selectFilteredOrders, selectOrdersObj } from '@/models/orders'
import { WindowSizes, useOrdersFetch, useWindowSizes } from '@/utilities/hooks'
import Order from './components/Order'
import { HEADER_HEIGHT } from '@/components/Header'
import { ErrorContract, KdsOrderStatus, KdsPaymentStatus } from '@/types/api'
import { selectLocation } from '@/models/locations'
import AddBaseOrderButton, { ADD_BASE_ORDER_BUTTON_SIZE } from '@/components/AddBaseOrderButton'

interface ScreenContainerProps {
  sizes: WindowSizes
}

interface OrdersContainerProps {
  columns: number
}

const ScreenContainer = styled.div<ScreenContainerProps>`
  height: ${({ sizes }) => sizes.height - HEADER_HEIGHT - 1}px;
  position: relative;
`

const ContentContainer = styled.div`
  background-color: #bfbfbf;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 0.0625rem;
  height: 100%;
`

const OrdersContainer = styled.div<OrdersContainerProps>`
  background-color: #ffffff;
  overflow-y: auto;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  align-content: start;
  gap: 1rem;
`

const OrdersLeftContainer = styled(OrdersContainer)`
  padding-bottom: calc(1rem + ${ADD_BASE_ORDER_BUTTON_SIZE}px + 1rem);
`

const SpinnerContainer = styled(ScreenContainer)`
  padding: 1rem;
  display: grid;
  place-items: center;
`

const AddBaseOrderButtonContainer = styled.div`
  position: absolute;
  left: 1rem;
  bottom: 1rem;
`

const Orders = () => {
  const [receiptDialogOpen, setReceiptDialogOpen] = useState<boolean>(false)
  const [markPaidDialogOpen, setMarkPaidDialogOpen] = useState<boolean>(false)
  const [selectedOrderId, setSelectedOrderId] = useState<string>('')

  const { locationId } = useParams<{ locationId: string }>()

  const orders = useSelector((state: RootState) => selectFilteredOrders(state, locationId))
  const location = useSelector((state: RootState) => selectLocation(state, locationId))
  const ordersObj = useSelector(selectOrdersObj)

  const markOrderReadyLoading = useSelector((state: RootState) => state.loading.effects.orders.markOrderReady)
  const markOrderPaidLoading = useSelector((state: RootState) => state.loading.effects.orders.markOrderPaid)
  const markOrderFinishedLoading = useSelector((state: RootState) => state.loading.effects.orders.markOrderFinished)
  const sendInvoiceLoading = useSelector((state: RootState) => state.loading.effects.orders.sendInvoice)

  const dispatch = useDispatch<Dispatch>()

  const sizes = useWindowSizes()

  const { initLoading } = useOrdersFetch({ locationId, playOrdersSound: true })

  const loading = useMemo(() => {
    return markOrderReadyLoading || markOrderPaidLoading || sendInvoiceLoading || markOrderFinishedLoading
  }, [markOrderReadyLoading, markOrderPaidLoading, sendInvoiceLoading, markOrderFinishedLoading])

  const columns = useMemo(() => {
    const allOrders = orders.created.concat(orders.ready)

    const foundOrder = allOrders.some((order) => {
      if (typeof order.number === 'undefined') {
        return false
      }

      return String(order.number).length > 4
    })

    return foundOrder ? 3 : 4
  }, [orders.created, orders.ready])

  const toggleReceiptDialog = () => {
    setReceiptDialogOpen((prevReceiptDialogOpen) => !prevReceiptDialogOpen)
  }

  const toggleMarkPaidDialog = () => {
    setMarkPaidDialogOpen((prevMarkPaidDialogOpen) => !prevMarkPaidDialogOpen)
  }

  const handleOrderClick = (orderId: string) => {
    const order = ordersObj[orderId]
    if (!order) {
      return
    }

    if (order.paymentStatus?.toLowerCase() === KdsPaymentStatus.Unpaid.toLowerCase()) {
      handleMarkOrderPaid(orderId)
      return
    }

    if (order.status?.toLowerCase() === KdsOrderStatus.Created.toLowerCase()) {
      handleMarkOrderReady(orderId)
      return
    }

    handleMarkOrderFinished(orderId)
  }

  const handleMarkOrderPaid = (orderId: string) => {
    setSelectedOrderId(orderId)

    if (location?.enableCashOrdersConfirmation) {
      toggleMarkPaidDialog()
    } else {
      handleMarkPaidDialogConfirm(orderId)
    }
  }

  const handleMarkOrderReady = async (orderId: string) => {
    try {
      await dispatch.orders.markOrderReady(orderId)
    } catch (error) {
      console.error(error)

      if (!isAxiosError<ErrorContract>(error)) {
        return
      }

      alert(error.response?.data.message)
    }
  }

  const handleMarkOrderFinished = async (orderId: string) => {
    try {
      await dispatch.orders.markOrderFinished(orderId)
    } catch (error) {
      console.error(error)

      if (!isAxiosError<ErrorContract>(error)) {
        return
      }

      alert(error.response?.data.message)
    }
  }

  const handleOrderReceiptClick = (orderId: string) => {
    setSelectedOrderId(orderId)

    toggleReceiptDialog()
  }

  const handleReceiptDialogDone = async (email: string) => {
    try {
      await dispatch.orders.sendInvoice({ orderId: selectedOrderId, email })

      toggleReceiptDialog()
    } catch (error) {
      console.error(error)

      if (!isAxiosError<ErrorContract>(error)) {
        return
      }

      alert(error.response?.data.message)
    }
  }

  const handleMarkPaidDialogConfirm = async (orderId?: string) => {
    try {
      await dispatch.orders.markOrderPaid(orderId || selectedOrderId)

      setMarkPaidDialogOpen(false)
    } catch (error) {
      console.error(error)

      if (!isAxiosError<ErrorContract>(error)) {
        return
      }

      alert(error.response?.data.message)
    }
  }

  if (initLoading) {
    return (
      <SpinnerContainer sizes={sizes}>
        <Spinner />
      </SpinnerContainer>
    )
  }

  return (
    <>
      <ScreenContainer sizes={sizes}>
        <ContentContainer>
          <OrdersLeftContainer columns={columns}>
            {orders.created.map((order) => (
              <div key={order.id}>
                <Order
                  order={order}
                  disabled={loading}
                  createdOrderBackgroundColor={location?.createdOrderBackgroundColor!}
                  enableEmailButton={location?.enableEmailButton}
                  onOrderClick={handleOrderClick}
                  onOrderReceiptClick={handleOrderReceiptClick}
                />
              </div>
            ))}
          </OrdersLeftContainer>

          <OrdersContainer columns={columns}>
            {orders.ready.map((order) => (
              <div key={order.id}>
                <Order
                  order={order}
                  disabled={loading}
                  readyOrderBackgroundColor={location?.readyOrderBackgroundColor!}
                  enableEmailButton={location?.enableEmailButton}
                  onOrderClick={handleOrderClick}
                  onOrderReceiptClick={handleOrderReceiptClick}
                />
              </div>
            ))}
          </OrdersContainer>
        </ContentContainer>

        <AddBaseOrderButtonContainer>
          <AddBaseOrderButton locationId={locationId} />
        </AddBaseOrderButtonContainer>
      </ScreenContainer>

      <ReceiptDialog
        open={receiptDialogOpen}
        disabled={sendInvoiceLoading}
        onDone={handleReceiptDialogDone}
        onClose={toggleReceiptDialog}
      />

      <MarkPaidDialog
        open={markPaidDialogOpen}
        disabled={markOrderPaidLoading}
        onConfirm={handleMarkPaidDialogConfirm}
        onClose={toggleMarkPaidDialog}
      />
    </>
  )
}

export default Orders
