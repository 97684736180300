import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { VolumeOff, VolumeUp, Logout } from '@mui/icons-material'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Menu, MenuItem } from '@mui/material'
import { MouseEvent, useState } from 'react'

import { Dispatch, RootState } from '@/utilities/store'
import { BrandLogo, HamburgerMenu, IconButton, Printer } from '@/components'
import { logout } from '@/utilities/functions'
import LocationsSelect from './components/LocationsSelect'

export const HEADER_HEIGHT = 80

const ROUTES: { [key: string]: string | undefined } = {
  orders: '/orders',
  kitchen: '/kitchen',
}

interface HeaderProps {
  menuOpen?: boolean
  onMenuClick?: () => void
}

const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
  height: ${HEADER_HEIGHT}px;
`

const HeaderRightContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
  align-items: center;
  column-gap: 1rem;
`

const HeaderLeftContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  column-gap: 1rem;
`

const Header = ({ menuOpen, onMenuClick }: HeaderProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const soundEnabled = useSelector((state: RootState) => state.app.soundEnabled)
  const locations = useSelector((state: RootState) => state.locations.locations)
  const token = useSelector((state: RootState) => state.authentication.token)

  const dispatch = useDispatch<Dispatch>()

  const navigate = useNavigate()
  const { locationId } = useParams<{ locationId: string }>()
  const location = useLocation()

  const toggleSoundEnabled = () => {
    dispatch.app.setSoundEnabled(!soundEnabled)
  }

  const handleOpen = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    handleClose()

    logout()

    navigate('/', { replace: true })
  }

  const handleLocationSelect = (selectedLocationId: string) => {
    const values = location.pathname.split('/')

    const lastValue = values[values.length - 1]

    const route = ROUTES[lastValue]
    if (!route) {
      return
    }

    navigate(`/${selectedLocationId}${route}`, { replace: true })
  }

  return (
    <HeaderContainer>
      <HeaderLeftContainer>
        {!!token && <HamburgerMenu open={menuOpen} onClick={onMenuClick} />}

        <BrandLogo />
      </HeaderLeftContainer>

      {!!token && (
        <HeaderRightContainer>
          <LocationsSelect
            locations={locations}
            selectedLocationId={locationId}
            onLocationSelect={handleLocationSelect}
          />

          <IconButton
            EnabledIcon={VolumeUp}
            DisabledIcon={VolumeOff}
            enabled={soundEnabled}
            onClick={toggleSoundEnabled}
          />

          <Printer />

          <div>
            <IconButton EnabledIcon={Logout} DisabledIcon={Logout} enabled onClick={handleOpen} />

            <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        </HeaderRightContainer>
      )}
    </HeaderContainer>
  )
}

export default Header
