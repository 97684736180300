import { ChangeEventHandler, useEffect, useState } from 'react'

import Dialog, { DialogProps } from '@/components/Dialog'
import EmailInput from './components/EmailInput'

interface ReceiptDialogProps extends DialogProps {
  onDone?: (email: string) => void
}

const ReceiptDialog = ({ onDone, ...props }: ReceiptDialogProps) => {
  const [email, setEmail] = useState<string>('')

  const handleChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (e) => {
    setEmail(e.target.value)
  }

  const handleConfirm = () => {
    onDone?.(email)
  }

  useEffect(() => {
    if (!props.open) {
      return
    }

    setEmail('')
  }, [props.open])

  return (
    <Dialog confirmButtonText="Done" onConfirm={handleConfirm} {...props}>
      <EmailInput
        label="Enter email to send a receipt"
        placeholder="Enter email address"
        required
        disabled={props.disabled}
        value={email}
        onChange={handleChange}
      />
    </Dialog>
  )
}

export default ReceiptDialog
