import { BUNDLE_ID } from './constants'

export interface PrintData {
  type: 'text' | 'image'
  textSize?: number
  text?: string
  newLines?: number
  center?: boolean
  ctx?: CanvasRenderingContext2D
}

const getNewLines = (newLines: number) => {
  return Array.from({ length: newLines }).reduce<string>((text) => (text += '\n'), '')
}

export const print = (printerIpAddress: string, data: PrintData[]) => {
  return new Promise<any>((resolve, reject) => {
    // Create a print document
    const builder = new window.epson.ePOSBuilder()
    builder.addTextLang('en')
    builder.addTextSmooth(true)
    builder.addTextFont(builder.FONT_A)

    data.forEach((d) => {
      switch (d.type) {
        case 'text': {
          if (!d.text) {
            break
          }

          if (d.textSize) {
            builder.addTextSize(d.textSize, d.textSize)
          }
          if (d.center) {
            builder.addTextAlign(builder.ALIGN_CENTER)
          }
          builder.addText(d.text)
          // Need to reset alignment after centered
          if (d.center) {
            builder.addTextAlign(builder.ALIGN_LEFT)
          }
          if (d.newLines) {
            builder.addText(getNewLines(d.newLines))
          }

          break
        }
        case 'image': {
          if (!d.ctx) {
            break
          }

          builder.addTextAlign(builder.ALIGN_CENTER)
          builder.addImage(d.ctx, 0, 0, d.ctx.canvas.width, d.ctx.canvas.height)
          // Need to reset alignment after centered
          builder.addTextAlign(builder.ALIGN_LEFT)
          if (d.newLines) {
            builder.addText(getNewLines(d.newLines))
          }

          break
        }
        default:
          break
      }
    })

    builder.addCut(builder.CUT_FEED)

    const request = builder.toString()

    // Set the end point address
    const address = `${
      window.navigator.userAgent.includes(BUNDLE_ID) ? 'http' : 'https'
    }://${printerIpAddress}/cgi-bin/epos/service.cgi?devid=local_printer&timeout=30000`

    // Create an ePOS-Print object
    const epos = new window.epson.ePOSPrint(address)

    // Send the print document
    epos.send(request)

    epos.onreceive = (res: any) => {
      resolve(res)
    }

    epos.onerror = (error: any) => {
      reject(error)
    }
  })
}
