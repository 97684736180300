import { Box, InputLabel, TextField, OutlinedTextFieldProps } from '@mui/material'
import { AlternateEmail } from '@mui/icons-material'
import styled, { AnyStyledComponent } from 'styled-components'

interface EmailInputProps extends Omit<OutlinedTextFieldProps, 'variant'> {}

const Label = styled(InputLabel as AnyStyledComponent).attrs({
  sx: { color: '#000000' },
})``

const EmailIcon = styled(AlternateEmail as unknown as AnyStyledComponent).attrs({
  fontSize: 'large',
})`
  color: #000000;
`

const EmailInput = ({ label, required, ...props }: EmailInputProps) => {
  return (
    <Box p={2} display="flex" alignItems="center" columnGap={3}>
      <EmailIcon />

      <Box flex={1}>
        {!!label && (
          <Box pb={1}>
            <Label required={required}>{label}</Label>
          </Box>
        )}

        <TextField type="email" size="small" fullWidth required={required} {...props} />
      </Box>
    </Box>
  )
}

export default EmailInput
