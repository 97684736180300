import styled, { AnyStyledComponent } from 'styled-components'
import { NavLink, NavLinkProps, To, useParams } from 'react-router-dom'
import { useMemo } from 'react'
import { Category, Restaurant } from '@mui/icons-material'
import { Typography } from '@mui/material'

import { Icon } from '@/types/mui'

interface Link {
  to: To
  Icon: Icon
  title: string
}

const MainContainer = styled.ul`
  padding: 1rem;
`

const NavLinkStyled = styled(NavLink as AnyStyledComponent).attrs({
  style: ({ isActive }) => ({ color: isActive ? '#1976d2' : '#000000' }),
} as NavLinkProps)`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  column-gap: 1rem;
  padding: 1rem;

  :hover {
    background-color: rgba(191, 191, 191, 0.15);
  }
`

const Sidebar = () => {
  const { locationId } = useParams<{ locationId: string }>()

  const links = useMemo((): Link[] => {
    return [
      { to: `/${locationId}/orders`, Icon: Category, title: 'Orders' },
      { to: `/${locationId}/kitchen`, Icon: Restaurant, title: 'Kitchen' },
    ]
  }, [locationId])

  return (
    <MainContainer>
      {links.map(({ Icon, title, to }, index) => (
        <li key={index}>
          <NavLinkStyled to={to}>
            <Icon />
            <Typography>{title}</Typography>
          </NavLinkStyled>
        </li>
      ))}
    </MainContainer>
  )
}

export default Sidebar
