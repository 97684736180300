import { Close } from '@mui/icons-material'
import { IconButton, IconButtonProps } from '@mui/material'
import styled, { AnyStyledComponent } from 'styled-components'

interface CloseButtonProps extends IconButtonProps {}

const IconButtonStyled = styled(IconButton as AnyStyledComponent).attrs((props) => ({
  size: 'small',
  type: 'button',
  sx: {
    backgroundColor: '#b8ccb8',
    '&:hover': {
      backgroundColor: '#b8ccb8',
    },
    '&:active': {
      backgroundColor: '#b8ccb8',
    },
    '&:focus': {
      backgroundColor: '#b8ccb8',
    },
  },
  ...props,
}))``

const CloseIcon = styled(Close as unknown as AnyStyledComponent).attrs({
  fontSize: 'large',
})`
  color: #000000;
`

const CloseButton = (props: CloseButtonProps) => {
  return (
    <IconButtonStyled {...props}>
      <CloseIcon />
    </IconButtonStyled>
  )
}

export default CloseButton
