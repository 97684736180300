import { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  DialogProps,
} from '@mui/material'

interface BaseOrderDialogProps extends DialogProps {
  loading?: boolean
  onCreate?: (orderNumber: number) => void
  onClose?: () => void
}

const BaseOrderDialog = ({ open, loading, onCreate, onClose }: BaseOrderDialogProps) => {
  const [orderNumber, setOrderNumber] = useState<string>('')

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOrderNumber(e.target.value.replace(/[^0-9]/g, ''))
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    onCreate?.(Number(orderNumber))
  }

  useEffect(() => {
    if (open) {
      return
    }

    setOrderNumber('')
  }, [open])

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <Box component="form" onSubmit={handleSubmit}>
        <DialogTitle>Create order</DialogTitle>

        <DialogContent>
          <Grid container py={1}>
            <Grid item xs>
              <TextField
                name="number"
                type="number"
                label="Order number"
                fullWidth
                required
                disabled={loading}
                inputProps={{ min: 0, inputMode: 'numeric', pattern: '[0-9]*' }}
                value={orderNumber}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>

          <Button type="submit" disabled={loading}>
            Create
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default BaseOrderDialog
