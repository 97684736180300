import { Models } from '@rematch/core'

import { orders } from '@/models/orders'
import { app } from '@/models/app'
import { authentication } from '@/models/authentication'
import { locations } from '@/models/locations'

export interface RootModel extends Models<RootModel> {
  orders: typeof orders
  app: typeof app
  authentication: typeof authentication
  locations: typeof locations
}

export const models: RootModel = { orders, app, authentication, locations }
